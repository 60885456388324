:root {
  --piece-cell-size: 32px;
  --num-rows: 15;
  --num-columns: 10;
}

#game {
  height: calc(var(--piece-cell-size) * var(--num-rows));
  width: calc(var(--piece-cell-size) * var(--num-columns));
  border: 5px solid #9d9a9a;
  background-color: #f3f0f0;
  margin: 0 auto;
  text-align: center;
  position: relative;

}

.piece-cell {
  position: absolute;
  width: var(--piece-cell-size);
  height: var(--piece-cell-size);
  background-color: #DDD;
  box-sizing: border-box;
  border: 4px solid #EEE;
  transition: opacity 1s ease-out;
}

#next-container {
  position: absolute;
  right: -60px;
}

#next-container #next-pieces {
  position: absolute;
  top: 20px;
  left: -65px;
}

#next-container .piece-cell {
  width: 16px;
  height: 16px;
  border: 2px solid #EEE;
}

#next-container .piece-container {
  position: relative;
}

#next-container .piece-container:nth-child(2) {
  left: 52px;
}

#next-container .piece-container:nth-child(3) {
  left: 105px;
}

.piece-cell.matched {
  opacity: 0;
}

#heading {
  text-align: center;
}

.game-title {
  font-size: 64px;
}

.instructions {
  position: absolute;
  width: 100%;
  margin-top: 40%;
  z-index: 100;
}

.instructions p {
  margin: 0;
  padding: 10px;
  display: inline-block;
  border: 2px solid #9d9a9a;
  background: #EFE1E1;
}

.instructions table {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 60px;
}

.piece-cell.yellow {
  background: radial-gradient(#e3e339bd, #ffff00);
  border: 4px solid #e7eb00;
}

.piece-cell.green {
  background: radial-gradient(#00800069, green);
  border: 4px solid #3fc528;
}

.piece-cell.blue {
  background: radial-gradient(#1690e5b0, #1690e5);
  border: 4px solid #7eb8f5;
}

.piece-cell.purple {
  background: radial-gradient(#c516e7ad, #c516e7);
  border: 4px solid #f9adf9;
}

.piece-cell.orange {
  background: radial-gradient(#ffa50073, orange);
  border: 4px solid #fdd68f;
}

.piece-cell.silver {
  background: radial-gradient(#e5e3e3, #c3b3b3);
  border: 4px solid #e3e1e1;
}

.piece-cell.red {
  background: radial-gradient(#f9181861, #f91818);
  border: 4px solid #f3c3c3;
}
